<template>
  <div>
    <!-- Table Container Card -->
    <b-card>
      <b-row>
        <b-col cols="8" md="10">
          <label for="basic-password">Search</label>
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchQuery"
              placeholder="Find Your Matters..."
              autofocus
              size="lg"
              autocomplete="off"
              class="no-outline"
              type="search"
              debounce="500"
            />
          </b-input-group>
        </b-col>
        <b-col cols="4" md="2">
          <label for="basic-password">Select Type</label>
          <v-select
            v-model="statusFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            class="invoice-filter-select cursor-pointer select-size-lg"
            placeholder="Status"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </v-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-table
        ref="refInvoiceListTable"
        :items="fetchInvoices"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <template #cell(title)="data">
          <div
            class="mb-1 text-primary cursor-pointer"
            v-b-tooltip.hover
            title="View Matter"
            @click="
              $router.push({
                name: 'dashboard-matter-preview',
                params: { id: data.item._id }
              })
            "
          >
            <p
              class="text-secondary"
              v-if="Object.keys(data.item).includes('file_no')"
            >
              File No.: {{ data.item.file_no }}
            </p>
            <h6 class="text-primary">
              {{ data.item.title }}
            </h6>
            <div v-if="Object.keys(data.item).includes('next_date')">
              <small
                class="m-0 text-success font-weight-bold"
                v-if="
                  new Date(new Date().setDate(new Date().getDate() - 1)) <=
                  new Date(data.item.next_date)
                "
              >
                Next Hearing:
                {{
                  formatDate(data.item.next_date, {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })
                }}
              </small>
            </div>
          </div>
        </template>

        <!-- Column: Unique Id -->
        <template #cell(unique_id)="data">
          <b-col>
            <h6 class="text-nowrap font-weight-bold" v-if="data.item.unique_id">
              {{ getUniqueId(data.item) }}
            </h6>
            <div v-if="Object.keys(data).includes('ctitle')">
              <small class="text-secondary font-italic">{{
                data.ctitle
              }}</small>
            </div>
          </b-col>
        </template>

        <!-- Column: Balance -->
        <template #cell(case_status)="data">
          <template v-if="data.item.case_status.toLowerCase() === 'pending'">
            <b-badge pill variant="light-primary">
              {{ data.item.case_status }}
            </b-badge>
          </template>
          <template v-else>
            <b-badge pill variant="light-danger">
              {{ data.item.case_status }}
            </b-badge>
          </template>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-badge
              variant="primary"
              pill
              v-b-tooltip.hover
              title="View Matter"
              class="badge-round cursor-pointer mr-1"
              @click="
                $router.push({
                  name: 'dashboard-matter-preview',
                  params: { id: data.item._id }
                })
              "
            >
              <feather-icon
                :id="`matter-${data.item._id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mr-25"
              />
              <span>View</span>
            </b-badge>

            <!-- <b-badge
            variant="danger"
            pill
            v-b-tooltip.hover
            title="Delete Matter"
            class="badge-round cursor-pointer"
            @click="deleteAlert(data.item._id)"
          >
            <feather-icon
              :id="`matter-${data.item._id}-preview-icon`"
              icon="TrashIcon"
              size="16"
            />
          </b-badge> -->
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalInvoices"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  VBTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'

// import store from '@/store'
import useFilterList from './useFilterList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {}
  },
  beforeMount() {},
  methods: {},
  beforeCreate() {},
  setup(props) {
    const statusOptions = ['Pending', 'Closed', 'All']

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData
    } = useFilterList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      statusOptions,
      formatDate,

      refetchData,

      avatarText
    }
  }
}
</script>
