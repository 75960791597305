import { ref, watch, computed } from '@vue/composition-api'
// import store from '@/store'
import store from '@/store/index.js'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFilterList() {
  // Use toast
  // const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'unique_id', label: 'Case Ref. Number', sortable: false },
    { key: 'title', sortable: false },
    { key: 'case_status', label: 'Status', sortable: false },
    { key: 'actions' }
  ]
  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref('All')

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value
      ? refInvoiceListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    if (searchQuery.value) {
      let queryParams = {
        perPage: perPage.value,
        page: currentPage.value,
        query: searchQuery.value
      }

      if (statusFilter.value) {
        queryParams['type'] = statusFilter.value.toLowerCase().trim()
      } else {
        queryParams['type'] = 'all'
      }

      store
        .dispatch('matterStore/fetchMatters', {
          ctx: this,
          queryParams: queryParams
        })
        .then((response) => {
          console.log({ Matters: response })
          callback(response.data)
          totalInvoices.value = response.meta.total
        })
        .catch((e) => {
          console.log(e)
        })
    }else{
        callback([])
        totalInvoices.value = 0
    }
  }

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    refetchData
  }
}
