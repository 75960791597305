export default [
  {
    header: 'Dashboard'
  },
  {
    title: 'Dashboards',
    route: 'analytics',
    icon: 'GridIcon'
  },
  {
    title: 'Matters',
    route: 'matters',
    icon: 'ServerIcon'
  },
  // {
  //   title: 'Court Hearing',
  //   route: 'court-hearing',
  //   icon: 'CalendarIcon',
  // },
  {
    title: 'Causelist',
    route: 'cause-list',
    icon: 'FileTextIcon'
  },
  {
    title: 'Display Board',
    route: 'display-board',
    icon: 'LayoutIcon'
  },
  {
    title: 'Legal Updates',
    route: 'lawtrend-news',
    icon: 'BookOpenIcon'
  },
  {
    header: 'Trackers'
  },
  {
    title: 'FIR Tracker',
    route: 'fir-tracker-list',
    icon: 'ClockIcon'
  },
  {
    title: 'Appeal Tracker',
    route: 'sci-tracker-list',
    icon: 'BarChart2Icon'
  },
  {
    title: 'Caveat Tracker',
    route: 'up-caveat-list',
    icon: 'WatchIcon'
  }
]
