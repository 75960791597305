<template>
  <div v-if="notification !== null">
    <b-link
      :key="notification._id"
      @click="openMatter(notification.matter_id, notification._id)"
    >
      <b-media>
        <template #aside>
          <b-avatar size="32" variant="info" class="mt-1">
            <feather-icon icon="BellIcon" />
          </b-avatar>
        </template>
        <b-col class="media-heading">
          <span>
            <b-row class="justify-content-between px-1">
              <span class="text-success"> Case has been updated </span>
              <small class="notification-text text-info">{{
                getSubDate(notification.datetime)
              }}</small>
            </b-row>
          </span>
          <small class="text-secondary font-italic">{{
            notification.ctitle
          }}</small>
          <div v-if="Object.keys(notification).includes('read')">
            <p
              v-if="notification.read === true"
              class="font-weight-bold text-dark m-0"
            >
              {{ notification.data.title }}
            </p>
            <p v-else class="font-weight-bold text-primary m-0">
              {{ notification.data.title }}
            </p>
          </div>
          <p v-else class="font-weight-bold text-primary m-0">
            {{ notification.data.title }}
          </p>
        </b-col>
      </b-media>
    </b-link>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BMedia, BCol, BLink, BAvatar, BRow } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: { BMedia, BLink, BAvatar, BRow, BCol },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    let userData = JSON.parse(localStorage.getItem('userData'))
    return {
      notification: this.data,
      user: userData
    }
  },
  methods: {
    getSubDate(date) {
      return moment(date.replaceAll('Z', '')).utc().fromNow()
    },
    openMatter(matter_id, _id) {
      this.$emit('hide-dropdown')
      this.$router.push({
        name: 'dashboard-matter-preview',
        params: { id: matter_id }
      })
      this.$store
        .dispatch('notificationStore/readNotification', { id: _id })
        .then((response) => {
          console.log('Notification --> ', response)
          if (response.success) {
            this.$emit('refetch-data')
          }
        })
    }
  }
}
</script>
