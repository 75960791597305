var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"8","md":"10"}},[_c('label',{attrs:{"for":"basic-password"}},[_vm._v("Search")]),_c('b-input-group',{staticClass:"mb-1"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{staticClass:"no-outline",attrs:{"placeholder":"Find Your Matters...","autofocus":"","size":"lg","autocomplete":"off","type":"search","debounce":"500"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('b-col',{attrs:{"cols":"4","md":"2"}},[_c('label',{attrs:{"for":"basic-password"}},[_vm._v("Select Type")]),_c('v-select',{staticClass:"invoice-filter-select cursor-pointer select-size-lg",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"placeholder":"Status"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(" "+_vm._s(label)+" ")])]}}]),model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1)],1),_c('b-card',{attrs:{"no-body":""}},[_c('b-table',{ref:"refInvoiceListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchInvoices,"responsive":"","fields":_vm.tableColumns,"primary-key":"_id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"head(invoiceStatus)",fn:function(){return [_c('feather-icon',{staticClass:"mx-auto",attrs:{"icon":"TrendingUpIcon"}})]},proxy:true},{key:"cell(title)",fn:function(data){return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mb-1 text-primary cursor-pointer",attrs:{"title":"View Matter"},on:{"click":function($event){return _vm.$router.push({
              name: 'dashboard-matter-preview',
              params: { id: data.item._id }
            })}}},[(Object.keys(data.item).includes('file_no'))?_c('p',{staticClass:"text-secondary"},[_vm._v(" File No.: "+_vm._s(data.item.file_no)+" ")]):_vm._e(),_c('h6',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(data.item.title)+" ")]),(Object.keys(data.item).includes('next_date'))?_c('div',[(
                new Date(new Date().setDate(new Date().getDate() - 1)) <=
                new Date(data.item.next_date)
              )?_c('small',{staticClass:"m-0 text-success font-weight-bold"},[_vm._v(" Next Hearing: "+_vm._s(_vm.formatDate(data.item.next_date, { month: 'long', day: 'numeric', year: 'numeric' }))+" ")]):_vm._e()]):_vm._e()])]}},{key:"cell(unique_id)",fn:function(data){return [_c('b-col',[(data.item.unique_id)?_c('h6',{staticClass:"text-nowrap font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getUniqueId(data.item))+" ")]):_vm._e(),(Object.keys(data).includes('ctitle'))?_c('div',[_c('small',{staticClass:"text-secondary font-italic"},[_vm._v(_vm._s(data.ctitle))])]):_vm._e()])]}},{key:"cell(case_status)",fn:function(data){return [(data.item.case_status.toLowerCase() === 'pending')?[_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(data.item.case_status)+" ")])]:[_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_vm._v(" "+_vm._s(data.item.case_status)+" ")])]]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"badge-round cursor-pointer mr-1",attrs:{"variant":"primary","pill":"","title":"View Matter"},on:{"click":function($event){return _vm.$router.push({
                name: 'dashboard-matter-preview',
                params: { id: data.item._id }
              })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"id":("matter-" + (data.item._id) + "-preview-icon"),"icon":"EyeIcon","size":"16"}}),_c('span',[_vm._v("View")])],1)],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalInvoices,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }