<template>
  <b-nav-item-dropdown
    class="dropdown-notification"
    ref="dropdown"
    menu-class="dropdown-menu-media w-600"
    right
  >
    <template #button-content>
      <feather-icon
        badge
        class="badge-minimal"
        badge-variant="success"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header">
        <b-row class="justify-content-between px-1">
          <h4 class="notification-title mb-0">Notifications</h4>
          <p
            class="text-primary cursor-pointer m-0"
            @click.prevent="onReadAllClick"
          >
            Read All
          </p>
        </b-row>
      </div>
    </li>

    <b-card class="m-0 p-0 rounded-0" no-body>
      <b-tabs nav-class="m-0 p-1" fill pills>
        <b-tab title="All" active lazy>
          <!-- Notifications -->
          <notification-list-view
            @hide-dropdown="hideDropdown"
            :type="'all'"
          ></notification-list-view>
        </b-tab>
        <b-tab title="Cases" lazy>
          <notification-list-view
            @hide-dropdown="hideDropdown"
            :type="'UPDATE_CASE'"
          ></notification-list-view>
        </b-tab>
        <b-tab title="Hearings" lazy>
          <notification-list-view
            @hide-dropdown="hideDropdown"
            :type="'WHATSAPP_CAUSELIST'"
          ></notification-list-view>
        </b-tab>
        <b-tab title="Orders" lazy>
          <notification-list-view
            @hide-dropdown="hideDropdown"
            :type="'ORDER_NEW'"
          ></notification-list-view>
        </b-tab>
      </b-tabs>
      <b-card-footer class="m-0 p-1">
        <b-col>
          <b-row class="justify-content-center">
            <b-button
              class="btn btn-primary"
              variant="primary"
              block
              @click.prevent="onReadClick"
              >VIEW ALL NOTIFICATIONS</b-button
            >
          </b-row>
        </b-col>
      </b-card-footer>
    </b-card>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BCardFooter,
  BCol,
  BRow,
  BBadge,
  BNavItemDropdown,
  BButton
} from 'bootstrap-vue'
import NotificationListView from './notification-list/NotificationListView.vue'

export default {
  components: {
    BTabs,
    BTab,
    BNavItemDropdown,
    BCardFooter,
    BCol,
    BRow,
    BBadge,
    BCard,
    BButton,
    NotificationListView
  },
  methods: {
    onReadClick() {
      this.$refs.dropdown.hide()
      this.$router.push({
        name: 'notification-screen'
      })
    },
    onReadAllClick() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You really want to mark all notifiaction as read.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('notificationStore/readAllNotification')
            .then((response) => {
              console.log('Notification --> ', response)
              if (response.success) {
                this.$swal({
                  icon: 'success',
                  title: 'Success!',
                  text: 'All notification marked as read!',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
              }else{
                this.$swal({
                  icon: 'danger',
                  title: 'Error!',
                  text: 'Failed to mark notifications as read!',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
              }
            })
        }
      })
    },
    hideDropdown() {
      this.$refs.dropdown.hide()
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      wheelPropagation: false
    }

    return {
      perfectScrollbarSettings
    }
  }
}
</script>

<style></style>
