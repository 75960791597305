<template>
  <!-- Table Container Card -->
  <b-card no-body class="m-0 p-0 rounded-0">
    <b-overlay
      :show="$store.state.notificationStore.NotificationLoading"
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <div v-if="notifications !== null">
        <vue-perfect-scrollbar
          v-once
          :settings="perfectScrollbarSettings"
          class="scrollable-container media-list scroll-area"
          tagname="li"
        >
          <div>
            <!-- <b-row class="justify-content-between px-2 pt-50">
              <p class="m-0"></p>
              <p
                class="text-primary cursor-pointer m-0"
                @click.prevent="onReadAllClick"
              >
                Read All
              </p>
            </b-row>
            <hr style="margin: 5px 0" /> -->
            <div v-for="notification in notifications">
              <div v-if="notification.type == 'UPDATE_CASE'">
                <CaseNotificationItem
                  @hide-dropdown="hideDropdown"
                  @refetch-data="fetchData"
                  :data="notification"
                ></CaseNotificationItem>
              </div>
              <div v-else-if="notification.type == 'WHATSAPP_CAUSELIST'">
                <HearingNotificationitem
                  @hide-dropdown="hideDropdown"
                  @refetch-data="fetchData"
                  :data="notification"
                ></HearingNotificationitem>
              </div>
              <div v-else-if="notification.type == 'ORDER_NEW'">
                <OrderNotificationItem
                  @hide-dropdown="hideDropdown"
                  @refetch-data="fetchData"
                  :data="notification"
                ></OrderNotificationItem>
              </div>
              <div v-else></div>
            </div>
          </div>
        </vue-perfect-scrollbar>
      </div>
      <div v-else>
        <vue-perfect-scrollbar
          v-once
          :settings="perfectScrollbarSettings"
          class="scrollable-container media-list scroll-area"
          tagname="li"
        ></vue-perfect-scrollbar>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay, BRow } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import CaseNotificationItem from '../components/CaseNotificationItem.vue'
import HearingNotificationitem from '../components/HearingNotificationitem.vue'
import OrderNotificationItem from '../components/OrderNotificationItem.vue'

export default {
  components: {
    BCard,
    BOverlay,
    BRow,
    VuePerfectScrollbar,
    CaseNotificationItem,
    HearingNotificationitem,
    OrderNotificationItem
  },
  props: {
    type: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      nType: this.type,
      notifications: null
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      let queryParams = {}
      if (this.nType !== 'all') {
        queryParams = {
          perPage: 10,
          page: 1,
          ntype: this.nType
        }
      } else {
        queryParams = {
          perPage: 10,
          page: 1
        }
      }
      //   Court Setup
      this.$store
        .dispatch('notificationStore/fetchNotifications', {
          ctx: this,
          queryParams: queryParams
        })
        .then((response) => {
          console.log('Notifications --> ', response)
          if (response.success) {
            this.notifications = response.data
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    hideDropdown() {
      this.$emit('hide-dropdown')
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      wheelPropagation: false
    }

    return {
      perfectScrollbarSettings
    }
  }
}
</script>
