<template>
  <!-- Error page-->
  <div v-if="notification !== null">
    <b-link
      :key="notification._id"
      @click="
        exportHearing({ url: notification.url, date: notification.datetime, _id: notification._id })
      "
    >
      <b-media>
        <template #aside>
          <b-avatar size="32" variant="success" class="mt-1">
            <feather-icon icon="FileTextIcon" />
          </b-avatar>
        </template>
        <b-col class="media-heading">
          <span>
            <b-row class="justify-content-between px-1">
              <span class="text-success"> Daily Cause List Added </span>
              <small class="notification-text text-info">{{
                getSubDate(notification.datetime)
              }}</small>
            </b-row>
          </span>
          <div v-if="Object.keys(notification).includes('read')">
            <p
              v-if="notification.read === true"
              class="font-weight-bold text-dark m-0">
            Confirmed Hearing on
            {{ getDate(notification.datetime) }}
          </p>
            <p v-else class="font-weight-bold text-primary m-0">
            Confirmed Hearing on
            {{ getDate(notification.datetime) }}
          </p>
          </div>
          <p v-else class="font-weight-bold text-primary m-0">
            Confirmed Hearing on
            {{ getDate(notification.datetime) }}
          </p>
        </b-col>
      </b-media>
    </b-link>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import axios from 'axios'
import { BMedia, BLink, BAvatar, BRow, BCol } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: { BMedia, BLink, BAvatar, BRow, BCol },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      notification: this.data,
    }
  },
  methods: {
    getDate(date) {
      return moment(date.replaceAll('Z', ''))
        .utc()
        .add(1, 'days')
        .format('ddd, DD MMM, YYYY')
    },
    getSubDate(date) {
      return moment(date.replaceAll('Z', '')).utc().fromNow()
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    exportHearing({ url, date, _id }) {
      let dd = moment(date.replaceAll('Z', ''))
        .utc()
        .add(1, 'days')
        .format('YYYY-MM-DD')
      let href = url.replace(
        'http://s3.mymunshi.live',
        'https://app.mymunshi.in/exports'
      )
      this.downloadAs(href, `hearings-${dd}.pdf`)
      this.$store
        .dispatch('notificationStore/readNotification', { id: _id })
        .then((response) => {
          console.log('Notification --> ', response)
          if (response.success) {
            this.$emit('refetch-data')
          }
        })
    }
  }
}
</script>
