export default [
  {
    header: 'Settings',
  },
  {
    title: 'Account Settings',
    route: 'account-setting',
    icon: 'UserIcon',
  },
  {
    title: 'Support Ticket',
    route: 'support',
    icon: 'MessageCircleIcon',
  }
]
  