<template>
  <div>
    <b-button
      v-b-modal.modal-create-matter
      variant="outline-primary"
      @click="initialSetup"
    >
      Create New
    </b-button>

    <!-- select 2 demo -->
    <b-modal
      id="modal-create-matter"
      title="Create New Matter"
      no-close-on-backdrop
      centered
      ok-title="submit"
      cancel-variant="outline-secondary"
    >
      <b-tabs fill pills v-model="CurrentTab" @changed="onTabChange">
        <!-- Case By Matter -->
        <b-tab title="Case By Matter" active>
          <b-card-text>
            <b-overlay
              :show="$store.state.matterStore.newMatterLoading"
              rounded="sm"
              variant="transparent"
              opacity="0.5"
              blur="2px"
            >
              <validation-observer ref="matterForm" #default="{ invalid }">
                <b-form @submit.prevent="fetchMatter">
                  <!-- Select Court -->
                  <validation-provider
                    #default="{ errors }"
                    name="Court"
                    vid="court"
                    rules="required"
                  >
                    <b-form-group label="Select Court" label-for="court">
                      <v-select
                        v-model="CourtSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="CourtOption"
                        label="text"
                        placeholder="Select Court"
                        @input="onCourtSelect"
                      />
                    </b-form-group>
                  </validation-provider>
                  <!-- Other Court -->
                  <div
                    v-if="
                      CourtSelected != null &&
                      CourtSelected.unique_id == 'oc-others'
                    "
                  >
                    <!-- <b-form-group
                      label="Court Name"
                      label-for="other-court-name"
                    >
                      <b-form-input
                        id="other-court-name"
                        rules="required"
                        v-model="CourtName"
                        placeholder="Enter Court Name"
                      />
                    </b-form-group> -->
                    <b-form-group
                      label="Court Name"
                      label-for="other-court-name"
                    >
                      <b-list-group>
                        <vue-autosuggest
                          :suggestions="FilteredOptions"
                          label="text"
                          :input-props="{
                            id: 'other-court-name',
                            class: 'form-control',
                            placeholder: 'Court Name'
                          }"
                          @input="onCourtNameInputChange"
                          @selected="onCourtNameSelected"
                        >
                          <!-- <template slot-scope="{ suggestion }">
                            <b-list-group-item>{{
                              suggestion.item
                            }}</b-list-group-item>
                          </template> -->
                        </vue-autosuggest>
                      </b-list-group>
                    </b-form-group>

                    <b-form-group label="Case Type" label-for="other-case-type">
                      <b-form-input
                        id="other-case-type"
                        v-model="OtherCaseType"
                        placeholder="Enter Case Type"
                      />
                    </b-form-group>
                    <div class="row">
                      <!-- Case Number -->
                      <div class="col-6">
                        <b-form-group
                          label="Case Number"
                          label-for="case-number"
                        >
                          <b-form-input
                            id="case-number"
                            v-model="CaseNumber"
                            placeholder="Enter Case Number"
                            @input="onCaseNumberChange"
                          />
                        </b-form-group>
                      </div>

                      <!-- Select Year -->
                      <div class="col-6">
                        <b-form-group label="Select Year" label-for="year">
                          <v-select
                            v-model="YearSelected"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="YearOption"
                            placeholder="Select Year"
                            @input="onYearSelect"
                          />
                        </b-form-group>
                      </div>
                    </div>
                    <b-form-group label="Case Title" label-for="case-title">
                      <b-form-textarea
                        id="case-title"
                        v-model="Title"
                        type="text"
                        placeholder="Case Title"
                        rows="3"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Hearing Date"
                      label-for="hearing-datepicker"
                    >
                      <b-form-datepicker
                        id="hearing-datepicker"
                        v-model="HearingDate"
                        class="mb-1"
                      />
                    </b-form-group>
                  </div>
                  <!-- Tribunals RERA -->
                  <div
                    v-else-if="
                      CourtSelected != null &&
                      (CourtSelected.unique_id.includes('tribunals-rera-up') ||
                        CourtSelected.unique_id.includes('tribunals-spst') ||
                        CourtSelected.unique_id.includes('tribunals-rccms'))
                    "
                  >
                    <!-- Case Number -->
                    <div>
                      <b-form-group
                        :label="
                          CourtSelected.unique_id.includes('tribunals-rccms')
                            ? 'Case Number'
                            : 'Consumer Number'
                        "
                        label-for="case-number"
                      >
                        <b-form-input
                          id="case-number"
                          rules="required"
                          v-model="CaseNumber"
                          :placeholder="
                            CourtSelected.unique_id.includes('tribunals-rccms')
                              ? 'Enter Case Number'
                              : 'Enter Consumer Number'
                          "
                          @input="onCaseNumberChange"
                        />
                      </b-form-group>
                    </div>
                    <div v-if="Title != null">
                      <b-form-group label="Case Title" label-for="case-title">
                        <b-form-textarea
                          id="case-title"
                          v-model="Title"
                          type="text"
                          placeholder="Case Title"
                          rows="3"
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <!-- Tribunals AFT -->
                  <div
                    v-else-if="
                      CourtSelected != null &&
                      (CourtSelected.unique_id.includes('tribunals-aft') ||
                        CourtSelected.unique_id.includes(
                          'tribunals-cgrf-haryana'
                        ))
                    "
                  >
                    <!-- Case Number -->
                    <div>
                      <b-form-group label="Case Number" label-for="case-number">
                        <b-form-input
                          id="case-number"
                          rules="required"
                          v-model="CaseNumber"
                          placeholder="Enter Case Number"
                          @input="onCaseNumberChange"
                        />
                      </b-form-group>
                    </div>
                    <div
                      v-if="
                        Title != null ||
                        CourtSelected.unique_id.includes(
                          'tribunals-cgrf-haryana'
                        )
                      "
                    >
                      <b-form-group label="Case Title" label-for="case-title">
                        <b-form-textarea
                          id="case-title"
                          v-model="Title"
                          type="text"
                          placeholder="Case Title"
                          rows="3"
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <!-- Normal Court -->
                  <div v-else>
                    <!-- Select Case Type -->
                    <div
                      v-if="
                        CourtSelected != null &&
                        (CourtSelected.unique_id.includes('drat') || CourtSelected.unique_id.includes('hc-punjab'))
                      "
                      class="demo-inline-spacing mb-1 mt-0 pt-0"
                    >
                      <b-form-radio
                        v-model="IsDairyNumber"
                        name="some-radios"
                        :value="false"
                      >
                        Case No.
                      </b-form-radio>
                      <b-form-radio
                        v-model="IsDairyNumber"
                        name="some-radios"
                        :value="true"
                      >
                        Dairy No.
                      </b-form-radio>
                    </div>

                    <b-form-group
                      v-if="!IsDairyNumber"
                      label="Select Case Type"
                      label-for="case-type"
                    >
                      <v-select
                        v-model="CaseSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :rules="IsDairyNumber ? `` : `required`"
                        :options="CaseOption"
                        placeholder="Select Case Type"
                        @input="onCaseTypeSelect"
                      />
                    </b-form-group>

                    <div class="row">
                      <!-- Case Number -->

                      <div class="col-6">
                        <b-form-group
                          v-if="IsDairyNumber"
                          label="Dairy Number"
                          label-for="case-number"
                        >
                          <b-form-input
                            id="case-number"
                            rules="required"
                            v-model="CaseNumber"
                            placeholder="Enter Dairy Number"
                            @input="onCaseNumberChange"
                          />
                        </b-form-group>
                        <b-form-group
                          v-else
                          label="Case Number"
                          label-for="case-number"
                        >
                          <b-form-input
                            id="case-number"
                            rules="required"
                            v-model="CaseNumber"
                            placeholder="Enter Case Number"
                            @input="onCaseNumberChange"
                          />
                        </b-form-group>
                      </div>

                      <!-- Select Year -->
                      <div v-if="CourtSelected != null && CourtSelected.unique_id.includes('hc-punjab') && IsDairyNumber">
                        
                      </div>
                      <div v-else class="col-6">
                        <b-form-group label="Select Year" label-for="year">
                          <v-select
                            v-model="YearSelected"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="YearOption"
                            rules="required"
                            placeholder="Select Year"
                            @input="onYearSelect"
                          />
                        </b-form-group>
                      </div>
                    </div>

                    <div v-if="Title != null">
                      <b-form-group label="Case Title" label-for="case-title">
                        <b-form-textarea
                          id="case-title"
                          v-model="Title"
                          type="text"
                          placeholder="Case Title"
                          rows="3"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </b-form>
              </validation-observer>
              <template #overlay>
                <div class="text-center">
                  <b-spinner variant="secondary"></b-spinner>
                  <p id="cancel-label">{{ loadingMsg }}</p>
                </div>
              </template>
            </b-overlay>
          </b-card-text>
        </b-tab>

        <!-- Case By CNR -->
        <b-tab title="Case By CNR">
          <validation-observer ref="cnrMatterForm" #default="{ invalid }">
            <b-overlay
              :show="$store.state.matterStore.newMatterLoading"
              rounded="sm"
              variant="transparent"
              opacity="0.5"
              blur="2px"
            >
              <b-form @submit.prevent="fetchCNR">
                <validation-provider
                  #default="validationContext"
                  name="CNR Number"
                  rules="required"
                >
                  <!-- CNR Number -->
                  <b-form-group label="CNR Number" label-for="cnr-number">
                    <b-form-input
                      id="cnr-name"
                      placeholder="Enter Case CNR Number"
                      v-model="CNRNumber"
                      :state="getValidationState(validationContext)"
                      @input="onCNRNumberChange"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <div v-if="CNRTitle != null">
                  <b-form-group label="Case Title" label-for="cnr-title">
                    <b-form-textarea
                      id="cnr-title"
                      v-model="CNRTitle"
                      type="text"
                      placeholder="Case Title"
                      rows="3"
                    />
                  </b-form-group>
                </div>
              </b-form>
              <template #overlay>
                <div class="text-center">
                  <b-spinner variant="secondary"></b-spinner>
                  <p id="cancel-label">{{ loadingMsg }}</p>
                </div>
              </template>
            </b-overlay>
          </validation-observer>
        </b-tab>
      </b-tabs>

      <!-- Footer -->
      <template #modal-footer>
        <!-- By CNR Footer -->
        <div class="w-100" v-if="CurrentTab == 1">
          <div v-if="CNRTitle == null">
            <b-button
              variant="primary"
              class="float-right"
              type="fetch"
              :disabled="
                !CNRNumber || $store.state.matterStore.newMatterLoading
              "
              @click="fetchCNR"
            >
              Fetch CNR
            </b-button>
          </div>
          <div v-if="CNRTitle != null">
            <b-button
              variant="primary"
              class="float-right"
              type="submit"
              :disabled="!CNRTitle || $store.state.matterStore.newMatterLoading"
              @click="submitCNR"
            >
              Save Case
            </b-button>
          </div>
          <div>
            <b-button
              variant="outline-dark"
              class="float-right mr-1 ml-1"
              type="cancel"
              @click="$bvModal.hide('modal-create-matter')"
            >
              Cancel
            </b-button>
          </div>
        </div>
        <!-- By Matter Footer -->
        <div class="w-100" v-else>
          <!-- Fetch Other Court -->
          <div
            v-if="
              CourtSelected != null && CourtSelected.unique_id == 'oc-others'
            "
          >
            <b-button
              variant="primary"
              class="float-right"
              type="submit1"
              :disabled="
                !Title ||
                !OtherCaseType ||
                !CaseNumber ||
                !YearSelected ||
                $store.state.matterStore.newMatterLoading
              "
              @click="submitOtherMatter"
            >
              Submit
            </b-button>
          </div>
          <!-- Fetch tribunals -->
          <div
            v-else-if="
              CourtSelected != null &&
              (CourtSelected.unique_id.includes('tribunals-rera-up') ||
                CourtSelected.unique_id.includes('tribunals-spst') ||
                CourtSelected.unique_id.includes('tribunals-rccms') ||
                CourtSelected.unique_id.includes('tribunals-aft'))
            "
          >
            <div v-if="Title == null">
              <b-button
                variant="primary"
                class="float-right"
                type="submit"
                :disabled="
                  CourtSelected == null ||
                  !CaseNumber ||
                  $store.state.matterStore.newMatterLoading
                "
                @click="fetchMatter"
              >
                Fetch Matter
              </b-button>
            </div>
            <div v-if="Title != null">
              <b-button
                variant="primary"
                class="float-right"
                type="submit1"
                :disabled="!Title || $store.state.matterStore.newMatterLoading"
                @click="submitMatter"
              >
                Submit
              </b-button>
            </div>
          </div>
          <!-- Fetch GCRF Haryana Tribunals -->
          <div
            v-else-if="
              CourtSelected != null &&
              CourtSelected.unique_id.includes('tribunals-cgrf-haryana')
            "
          >
            <div>
              <b-button
                variant="primary"
                class="float-right"
                type="submit"
                :disabled="
                  CourtSelected == null ||
                  !CaseNumber ||
                  !Title ||
                  $store.state.matterStore.newMatterLoading
                "
                @click="fetchMatter"
              >
                Submit
              </b-button>
            </div>
          </div>

          <div v-else>
            <div
              v-if="
                Title == null && (CourtSelected != null && CourtSelected.unique_id.includes('hc-punjab') && IsDairyNumber)
              "
            >
              <b-button
                variant="primary"
                class="float-right"
                type="submit"
                :disabled="
                  CourtSelected == null ||
                  !CaseNumber ||
                  $store.state.matterStore.newMatterLoading
                "
                @click="fetchMatter"
              >
                Fetch Matter
              </b-button>
            </div>

            <div v-else-if="Title == null">
              <b-button
                variant="primary"
                class="float-right"
                type="submit"
                :disabled="
                  CourtSelected == null ||
                  (CaseSelected == null && !IsDairyNumber) ||
                  !CaseNumber ||
                  YearSelected == null ||
                  $store.state.matterStore.newMatterLoading
                "
                @click="fetchMatter"
              >
                Fetch Matter
              </b-button>
            </div>
            <div v-if="Title != null">
              <b-button
                variant="primary"
                class="float-right"
                type="submit1"
                :disabled="!Title || $store.state.matterStore.newMatterLoading"
                @click="submitMatter"
              >
                Submit
              </b-button>
            </div>
            <div>
              <b-button
                variant="outline-dark"
                class="float-right mr-1 ml-1"
                type="cancel"
                @click="$bvModal.hide('modal-create-matter')"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </div>

        <b-list-group
          v-if="multipleCases.length && CurrentTab == 0"
          class="w-100"
        >
          <p class="mt-2">Cases List</p>
          <b-overlay
            :show="$store.state.matterStore.newMatterLoading"
            rounded="sm"
            variant="transparent"
            opacity="0.5"
            blur="2px"
          >
            <b-list-group-item
              v-for="(data, itemIndex) in multipleCases"
              v-bind:key="data.cnr"
              class="flex-column align-items-start"
            >
              <b-row class="justify-content-between p-0 m-0">
                <b-col class="mr-auto col-8 p-0 m-0">
                  <h5>{{ data.title.replace(/<\/?[^>]+(>|$)/g, '') }}</h5>
                  <p
                    class="p-0 m-0"
                    v-if="CourtSelected.unique_id.includes('tribunals-cgit')"
                  >
                    Case No.: {{ data.unique_case_number }} - [{{
                      data.case_status
                    }}]
                  </p>
                  <p class="p-0 m-0" v-else>{{ data.cnr }}</p>
                </b-col>
                <b-col class="col-4">
                  <b-row class="justify-content-end">
                    <b-button
                      variant="outline-primary"
                      v-if="
                        CourtSelected.unique_id.includes('tribunals-cgit') ||
                        CourtSelected.unique_id.includes(
                          'tribunals-aft-chandigarh'
                        )
                      "
                      @click="submitCGIT(data)"
                      >Add</b-button
                    >
                    <b-button
                      variant="outline-primary"
                      v-else
                      @click="fetchMultiCase(data.cnr)"
                      >Add</b-button
                    >
                  </b-row>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-overlay>
        </b-list-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BCardText,
  BFormTextarea,
  BFormGroup,
  BTabs,
  BTab,
  BOverlay,
  BFormRadio,
  BSpinner,
  BFormInvalidFeedback,
  BFormDatepicker,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue'
import { codeBasic } from '../../forms/form-element/autosuggest/code'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BCardText,
    BFormInvalidFeedback,
    BFormGroup,
    BTab,
    BTabs,
    vSelect,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BSpinner,
    BFormDatepicker,
    BListGroup,
    BListGroupItem,
    VueAutosuggest
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  data() {
    return {
      CurrentTab: 0,
      // Matter
      Title: null,
      CourtSelected: null,
      CourtOption: null,
      FilteredOptions: [],
      OtherCaseType: null,
      CaseSelected: null,
      HearingDate: null,
      CourtName: null,
      CaseOption: [],
      IsDairyNumber: false,
      CaseNumber: null,
      YearSelected: null,
      YearOption: [],
      // CNR
      CNRNumber: null,
      CNRTitle: null,

      // Data
      CurrentMatter: null,
      CurrentCNRMatter: null,
      loadingMsg: '',
      multipleCases: [],

      // validation rules
      required
    }
  },
  methods: {
    onCourtNameSelected(selected) {
      this.CourtName = selected.item
    },
    onCourtNameInputChange(text) {
      this.CourtName = text
      console.log(text)
      if (text === '' || text === undefined) {
        this.FilteredOptions = [
          {
            data: []
          }
        ]
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.CourtOption.filter(
        (item) =>
          item.text && item.text.toLowerCase().indexOf(text.toLowerCase()) > -1
      ).map((item) => item.text)

      this.FilteredOptions = [
        {
          data: filteredData
        }
      ]
    },
    initialSetup() {
      this.multipleCases = []
      const years = []
      for (var i = new Date().getFullYear(); i >= 1901; i--) {
        years.push(i)
      }
      this.years = years

      let allC = []
      const courtsList = []
      // ** Court Setup Starts
      const ll = this.$store.state.courtStore.courtsData
      console.log({ SDS: ll })
      if (ll) {
        const scl = ll.supremeCourts
          .filter(function (key) {
            if (Object.keys(key).includes('enabled')) {
              return key.enabled
            }
            return false
          })
          .map(function (key) {
            return {
              value: key._id,
              text: key.title,
              cslug: key.cli_slug,
              unique_id: key.unique_id
            }
          })
        const sc = {
          label: 'Supreme Court',
          options: scl
        }
        const hcl = ll.highCourts
          .filter(function (key) {
            if (Object.keys(key).includes('enabled')) {
              return key.enabled
            }
            return false
          })
          .map(function (key) {
            return {
              value: key._id,
              text: key.title,
              cslug: key.cli_slug,
              unique_id: key.unique_id
            }
          })
        const hc = {
          label: 'High Court',
          options: hcl
        }
        const dcl = ll.districtCourts
          .filter(function (key) {
            if (Object.keys(key).includes('enabled')) {
              return key.enabled
            }
            return false
          })
          .map(function (key) {
            return {
              value: key._id,
              text: key.title,
              cslug: key.cli_slug,
              unique_id: key.unique_id
            }
          })
        const dc = {
          label: 'District Court',
          options: dcl
        }
        const tcl = ll.tribunalsCourts
          .filter(function (key) {
            if (Object.keys(key).includes('enabled')) {
              return key.enabled
            }
            return false
          })
          .map(function (key) {
            return {
              value: key._id,
              text: key.title,
              cslug: key.cli_slug,
              unique_id: key.unique_id
            }
          })
        const tc = {
          label: 'Tribunals Court',
          options: dcl
        }
        const ocl = ll.othersCourts.map(function (key) {
          return {
            value: key._id,
            text: key.title,
            cslug: key.cli_slug,
            unique_id: key.unique_id
          }
        })
        const oc = {
          label: 'Other Court',
          options: dcl
        }

        courtsList.push(sc)
        courtsList.push(hc)
        courtsList.push(dc)
        courtsList.push(tc)
        courtsList.push(oc)
        allC = [...scl, ...hcl, ...dcl, ...tcl, ...ocl]
      }
      this.CourtOption = allC
      this.YearOption = years

      if (allC.length > 0) {
        this.CourtSelected = allC[0]
        console.log('CSCSC', this.CourtSelected)
      }
    },
    clearAllFields() {
      ;(this.CurrentTab = 0),
        // Matter
        (this.Title = null),
        (this.CourtSelected = null),
        (this.OtherCaseType = null),
        (this.CaseSelected = null),
        (this.CaseOption = []),
        (this.CaseNumber = null),
        this.CourtName == null,
        (this.IsDairyNumber = false),
        (this.CurrentMatter = null),
        (this.CurrentCNRMatter = null),
        (this.YearSelected = null),
        // CNR
        (this.CNRNumber = null),
        (this.CNRTitle = null)
    },
    onTabChange(currentTabs, previousTabs) {
      console.log({ Tabs: currentTabs, previousTabs })
      this.clearAllFields()
    },
    // Matters
    onCourtSelect(event) {
      ;(this.CaseSelected = null),
        (this.CaseOption = []),
        this.CourtName == null,
        (this.YearSelected = null),
        (this.Title = null),
        (this.IsDairyNumber = false)
      console.log(event)
      this.$store
        .dispatch('courtStore/fetchCourtTypes', event.value)
        .then((response) => {
          console.log('Case Types --> ', response)
          if (response) {
            this.CaseOption = response['case_types']
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onCaseTypeSelect(event) {
      this.Title = null
    },
    onYearSelect(event) {
      this.Title = null
    },
    onCaseNumberChange(event) {
      this.Title = null
    },
    fetchMatter() {
      this.loadingMsg = 'Please Wait, Connecting to the Court!'
      this.CurrentMatter = null
      this.multipleCases = []
      event.preventDefault()
      this.$refs.matterForm.validate().then((success) => {
        if (success) {
          let data
          if (this.CourtSelected.unique_id.includes('tribunals-cgrf-haryana')) {
            data = {
              case_number: this.CaseNumber,
              court_id: this.CourtSelected.value,
              title: this.Title
            }
          } else if (
            this.CourtSelected.unique_id.includes('tribunals-rera-up') ||
            this.CourtSelected.unique_id.includes('tribunals-spst') ||
            this.CourtSelected.unique_id.includes('tribunals-rccms') ||
            this.CourtSelected.unique_id.includes('tribunals-aft')
          ) {
            data = {
              case_number: this.CaseNumber,
              court_id: this.CourtSelected.value
            }
          } else {
            if (this.IsDairyNumber) {
              if (this.CourtSelected.unique_id.includes('hc-punjab')) {
                data = {
                  dairy_number: this.CaseNumber,
                  court_id: this.CourtSelected.value
                }
              } else {
                data = {
                  dairy_number: this.CaseNumber,
                  dairy_year: '' + this.YearSelected,
                  court_id: this.CourtSelected.value
                }
              }
            } else {
              data = {
                case_number: this.CaseNumber,
                case_type: this.CaseSelected.key,
                case_year: '' + this.YearSelected,
                court_id: this.CourtSelected.value
              }
            }
          }
          console.log('Data C', this.CourtSelected)
          store
            .dispatch('matterStore/findMatter', {
              ctx: this,
              payload: data,
              type: this.CourtSelected.unique_id
            })
            .then((response) => {
              console.log({ Matter: response })
              if (response != null) {
                if (response.success) {
                  console.log('Finded Matter -->', response)
                  if (Object.keys(response).includes('multi')) {
                    this.multipleCases = response.data
                  } else {
                    this.CurrentMatter = response.data
                    this.Title = response.data.title
                  }
                }
              }
            })
        }
      })
    },

    submitMatter() {
      this.loadingMsg = ''
      event.preventDefault()
      console.log('Submiting Matter')
      if (this.CurrentMatter != null && this.Title) {
        let data = {
          case_id: this.CurrentMatter.unique_id,
          title: this.Title
        }
        console.log('Data', data)
        store.dispatch('matterStore/saveMatter', {
          ctx: this,
          payload: data
        })
      }
    },

    submitOtherMatter() {
      this.loadingMsg = ''
      event.preventDefault()
      console.log('Submiting Matter')
      let data = {
        case_number: this.CaseNumber,
        case_type: this.OtherCaseType,
        case_year: '' + this.YearSelected,
        title: this.Title,
        other_court_name: this.CourtName,
        unique_id: this.CourtSelected.unique_id,
        hearing_date: this.HearingDate,
        case_status: 'PENDING'
      }
      console.log('Data', data)
      store.dispatch('matterStore/saveOtherMatter', {
        ctx: this,
        payload: data
      })
    },
    fetchMultiCase(cnr) {
      this.CNRNumber = cnr
      this.fetchCNR({ isMulti: true })
    },
    // CNR
    onCNRNumberChange(event) {
      this.CNRTitle = null
    },
    fetchCNR({ isMulti = false }) {
      this.loadingMsg = 'Please Wait, Connecting to the Court!'
      this.CurrentMatter = null
      event.preventDefault()
      this.$refs.cnrMatterForm.validate().then((success) => {
        if (success) {
          let data = {
            cnr: this.CNRNumber
          }
          console.log('Data', data)
          store
            .dispatch('matterStore/findDistrictMatter', {
              ctx: this,
              payload: data
            })
            .then((response) => {
              console.log({ Matter: response })
              if (response != null) {
                if (response.success) {
                  console.log('Finded CNR Matter -->', response)
                  this.CurrentCNRMatter = response.data
                  if (isMulti) {
                    this.submitCNR({ multi: true })
                  } else {
                    this.CNRTitle = response.data.title
                  }
                }
              }
            })
        }
      })
    },
    submitCNR({ multi = false }) {
      this.loadingMsg = ''
      event.preventDefault()
      console.log('Submiting Matter')
      if (this.CurrentCNRMatter != null && (this.CNRTitle || multi)) {
        let data
        if (multi) {
          data = {
            case_id: this.CurrentCNRMatter.unique_id,
            title: this.CurrentCNRMatter.title.replace(/<\/?[^>]+(>|$)/g, '')
          }
        } else {
          data = {
            case_id: this.CurrentCNRMatter.unique_id,
            title: this.CNRTitle
          }
        }
        console.log('Data', data)
        store.dispatch('matterStore/saveDistrictMatter', {
          ctx: this,
          payload: data,
          isMulti: multi
        })
      }
    },
    submitCGIT(data) {
      this.loadingMsg = ''
      event.preventDefault()
      console.log('Submiting Matter')
      console.log('Data', data)
      var ss = {
        court_id: this.CourtSelected.value,
        case: data
      }
      store.dispatch('matterStore/saveCGITMatter', {
        ctx: this,
        payload: ss
      })
    }
  },
  setup(props, context) {
    const { refFormObserver, getValidationState, resetForm } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
