<template>
  <div v-if="notification !== null">
    <b-link
      :key="notification._id"
      @click="
        exportJudgment({
          url: notification.data.url,
          date: notification.data.datetime,
          matter_id: notification.data.reference_id,
          _id: notification._id
        })
      "
    >
      <b-media>
        <template #aside>
          <b-avatar size="32" variant="light" class="mt-1">
            <b-img
              class="m-1"
              :src="require('@/assets/images/icons/legal-hammer.png?image=20')"
              alt="Responsive image"
            ></b-img>
          </b-avatar>
        </template>
        <b-col class="media-heading">
          <span>
            <b-row class="justify-content-between px-1">
              <span class="text-success"> New order has been added </span>
              <small class="notification-text text-info">{{
                getSubDate(notification.datetime)
              }}</small>
            </b-row>
          </span>
          <small class="text-secondary font-italic">{{
            notification.ctitle
          }}</small>
          <div v-if="Object.keys(notification).includes('read')">
            <p
              v-if="notification.read === true"
              class="font-weight-bold text-dark m-0"
            >
              {{ notification.matter_title }}
            </p>
            <p v-else class="font-weight-bold text-primary m-0">
              {{ notification.matter_title }}
            </p>
          </div>
          <p v-else class="font-weight-bold text-primary m-0">
            {{ notification.matter_title }}
          </p>
        </b-col>
      </b-media>
    </b-link>
  </div>
</template>

<script>
/* eslint-disable global-require */
import axios from 'axios'
import { BMedia, BLink, BAvatar, BRow, BCol, BImg } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BMedia,
    BLink,
    BAvatar,
    BRow,
    BCol,
    BImg
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      notification: this.data,
    }
  },
  methods: {
    getSubDate(date) {
      return moment(date.replaceAll('Z', '')).utc().fromNow()
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    exportJudgment({ url, date, matter_id, _id }) {
      let dd = date.toString().split('T')[0]
      let uu = matter_id.includes('-')
        ? matter_id.split('-').slice(2).join('/')
        : matter_id
      let href = url.replace(
        'http://s3.mymunshi.live',
        'https://app.mymunshi.in/exports'
      )
      this.downloadAs(href, `${uu}-order-${dd}.pdf`)
      this.$store
        .dispatch('notificationStore/readNotification', { id: _id })
        .then((response) => {
          console.log('Notification --> ', response)
          if (response.success) {
            this.$emit('refetch-data')
          }
        })
    }
  }
}
</script>
